export const getSubjectlist = async () => {
    const apiUrl = 'https://dev-api-serve.one.th/chat-center/public/form/formticket';
    const apiKey = 'MgzADJHQCrYs9ulYC9mO8gNd95os1BBy';

    const queryParams = new URLSearchParams({
        business_id: 'ab016927-9e3d-4d53-9e18-9f9fb1ea43ad',
        user_id: '36c46077-3adc-4c82-bf59-095d7e22fbad',
    }).toString();

    const urlWithParams = `${apiUrl}?${queryParams}`;

    try {
        const response = await fetch(urlWithParams, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        // console.error('Error fetching data:', error);
        throw error;
    }
};
