import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animationData from '../images/Animation_1735840686359.json';
const LineLogin = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // const lineLoginUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_LINE_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_LINE_REDIRECT_URI)}&state=randomState123&scope=profile%20openid`;
        const lineLoginUrl = 'https://api-test.benzspace.com/api/v1/line/login'
        setTimeout(() => {
            window.location.href = lineLoginUrl;
        }, 2000);
    }, [navigate]);

    return (
        <div style={styles.container}>
            {/* <video
                src="/Animation_1735836098618.webm"
                autoPlay
                loop
                muted
                style={styles.video}
            /> */}
            <Lottie animationData={animationData} style={styles.lottie} />
            <div style={styles.text}>กรุณารอสักครู่ ...</div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
    },
    video: {
        width: '80%',
        maxWidth: '300px',
        borderRadius: '10px',
    },
    lottie: {
        width: '80%',
        maxWidth: '300px',
        borderRadius: '10px',
    },
    text: {
        marginTop: '20px',
        fontSize: '18px',
        color: '#333',
    },
};

export default LineLogin;
